import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Header from "../components/Header"
import Project from "../components/Project"
import ProjectInfo from "../components/ProjectInfo"

import { layout_wrapper, projects_wrapper } from "./index.module.css"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulStartseite {
        projects {
          name
          type
          slug
          images {
            formation
            images {
              gatsbyImageData(
                width: 1500
                placeholder: BLURRED
                formats: [JPG, WEBP]
              )
              file {
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.contentfulStartseite.projects

  return (
    <Layout className={layout_wrapper}>
      <Header />
      <SEO title="Marie Grob Photography" />
      <section className={projects_wrapper} id="projects">
        {projects.map((project, i) => (
          <Project project={project} key={i} index={i} />
        ))}

        <ProjectInfo />
      </section>
    </Layout>
  )
}

export default IndexPage
