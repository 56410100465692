import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { photo_project, photo_row, photo } from "./project.module.css"

const Project = ({ project, index }) => {
  const projectImages = project.images

  let projectImagesCount = 0

  projectImages.map((p) => (projectImagesCount += p.images.length))

  const getAspectRatio = (w, h) => w / h

  return (
    <article
      className={photo_project}
      id={project.slug}
      data-index={index}
      data-type={project.type}
      data-images-count={projectImagesCount}
    >
      {projectImages.map((imageRow, i) => (
        <div
          key={i}
          className={photo_row}
          data-multi-col={imageRow.images.length === 2}
          data-formation={
            imageRow.images.length === 2
              ? imageRow.formation === "groß/klein"
                ? "big/small"
                : imageRow.formation === "klein/groß"
                ? "small/big"
                : "same-width"
              : "single"
          }
        >
          {imageRow.images.map((image, i) => (
            <GatsbyImage
              key={i}
              image={image.gatsbyImageData}
              style={{
                "--aspect-ratio": getAspectRatio(
                  image.file.details.image.width,
                  image.file.details.image.height
                ),
              }}
              layout="constrained"
              className={` ${photo} ${
                getAspectRatio(
                  image.file.details.image.width,
                  image.file.details.image.height
                ) >= 1
                  ? "w-full"
                  : "h-auto min-h-initial"
              }`}
            />
          ))}
        </div>
      ))}
    </article>
  )
}

export default Project
